<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-toolbar
      :tab="tab"
      :save="save"
      revert-after-save
      :revert="initToDo"
      no-history
    >
    </document-toolbar>
    <document-in-tab>
      <v-card class="work-items">
        <v-card-title>
          <span class="mt-n1"> {{$t('t.ToDo')}}</span>
        </v-card-title>
        <v-card-text class="py-0 card-text-full-height d-flex">
          <div
            class="no-data"
            v-if="items.length == 0"
          >
            {{$t('t.NoWorkItems')}}
          </div>
          <v-row
            v-else
            class="full-height"
          >
            <v-col
              cols="6"
              class="overflow-y py-0"
            >
              <v-list class="border-radius">
                <v-list-item
                  v-for="itemsGroupByDate in items"
                  :key="itemsGroupByDate.date"
                  :value="itemsGroupByDate.date"
                >
                  <div>
                    <calendar-date :date="itemsGroupByDate.date" />
                    <v-list class="border-radius">
                      <v-list-item
                        v-for="item in itemsGroupByDate.items"
                        :key="item.id"
                        :value="item.id"
                        @click="navigateTo(item)"
                        class="border-radius"
                      >
                        <div class="d-flex align-center py-2">
                          <v-checkbox
                            class="mt-0 pr-5"
                            hide-details
                            v-model="item.isChecked"
                            @click.stop
                          />
                          <div class="d-flex align-center">
                            <div class="mr-4">
                              <v-icon :color="`#${item.color}`">{{workItemTypeIcons[item.workItemType.code] || workItemTypeIconDefault }}</v-icon>
                            </div>
                            <div class="mr-4">
                              <user-ref
                                v-if="item.userId"
                                :id="item.userId"
                                hide-fullname
                                hide-group
                              />
                              <computed-document-ref
                                v-if="!item.userId && item.groupId"
                                :id="item.groupId"
                                :documentType="'groups'"
                                :options=options
                              />
                            </div>

                            <div>
                              <div class="d-flex">
                                <div class="font-weight-bold">{{item.workItemType.name}}</div>
                              </div>
                              <div class="description">{{item.description}}<span v-if="item.entityReference">#{{item.entityReference.number}}</span></div>
                              <div class="ref-detail d-flex mt-1">
                                <span>{{$t('t.CreateUpdateDate')}}</span>
                                <create-update-at-column :value="createUpdateDates(item)" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col
              cols=6
              class="full-height"
            >
              <v-form
                v-model="form"
                ref="form"
              >
                <div
                  v-if="itemsChecked.length"
                  class="mt-n15"
                >
                  <v-card
                    elevation=0
                    class="bordered-item"
                  >
                    <v-card-text>
                      <div class="title font-weight-bold">{{$t('t.WhatDoYouWantToDo')}}</div>
                      <v-btn-toggle
                        v-model="action"
                        @change="validateForm"
                        color="primary"
                        :mandatory="!!action"
                        class="mt-3 mb-5"
                      >
                        <v-btn
                          value="close"
                          :class="{ 'color-btn': !!action && action !== 'close', 'theme--light': !action }"
                        >
                          <v-icon class="mx-3">{{$icon('i.CloseToDo')}}</v-icon>
                          <span class="mr-3">{{$t('t.CloseToDo')}}</span>
                        </v-btn>
                        <v-btn
                          :class="{ 'color-btn': !!action && action !== 'report', 'theme-light': !action  }"
                          value="report"
                        >
                          <v-icon class="mx-3">{{$icon('i.Report')}}</v-icon>
                          <span class="mr-3">{{$t('t.Report')}}</span>
                        </v-btn>
                        <v-btn
                          value="reassign"
                          :class="{ 'color-btn': !!action && action !== 'reassign', 'theme-light': !action  }"
                        >
                          <v-icon class="mx-3">{{$icon('i.Reassign')}}</v-icon>
                          <span class="mr-3">{{$t('t.Reassign')}}</span>
                        </v-btn>
                      </v-btn-toggle>
                      <div v-if="action === 'report'">
                        <date-picker-menu
                          v-model="targetDate"
                          :label="$t('t.TargetDate')"
                          :min="moment().add(1,'days').format('YYYY-MM-DD')"
                          :rules="required"
                          :clearable="true"
                          bottom
                        />
                        <v-select
                          :rules="required"
                          :items="priorities"
                          item-text="text"
                          return-object
                          :label="$t('t.Priority')"
                          v-model="priority"
                        />
                      </div>
                      <div v-if="action === 'reassign' || action === 'report'">
                        <div
                          v-if="user"
                          class="subtitle-2"
                        >Assign to</div>
                        <user-picker
                          :placeholder="`${$t('t.AssignTo')}...`"
                          :selection.sync="user"
                          :scope="accountScope"
                          document-types="users"
                          :rules="required"
                        />
                      </div>
                      <v-switch
                        v-if="action === 'report' && allowFollowUpToBlockProtocol"
                        :disabled="isBlockingProtocolLocked"
                        v-model="isBlockingProtocol"
                        :label="$t('t.AllowDocumentToBlockTheProtocol')"
                      />
                      <div
                        v-if="action"
                        class="mt-4"
                      >
                        <v-textarea
                          :label="$t('t.Comment')"
                          v-model="comment"
                          :rows="6"
                        />
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </document-in-tab>
  </div>
</template>

<script>
import tabController from '@/tabController'
import recentsController from '@/recentsController'
import Scope from '@/pages/search/controllers/scope'
import { MainPresetPart } from '@/wasm/pkg'

export default {
  components: {
    CreateUpdateAtColumn: () => import('@/pages/search/components/columns/create-update-at-column'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    UserPicker: () => import('@/components/user-picker'),
    CalendarDate: () => import('@/components/calendar-date'),
    UserRef: () => import('@/components/documents-ref/user-ref'),
    ComputedDocumentRef: () => import('@/components/documents-ref/computed-document-ref')
  },
  data () {
    return {
      form1: false,
      allowFollowUpToBlockProtocol: false,
      isBlockingProtocolLocked: false,
      isBlockingProtocol: false,
      items: [],
      options: { hideNavigation: true },
      isLoading: false,
      workItemTypeIconDefault: this.$icon('i.GenericTask'),
      workItemTypeIcons: {
        email: this.$icon('i.Email'),
        phone: this.$icon('i.Phone'),
        disputePastTargetDate: this.$icon('i.Disputes'),
        brokenPromiseToPay: this.$icon('i.Promises'),
        invoiceContestation: this.$icon('i.Bell'),
        invoiceValidation: this.$icon('i.Bell'),
        collaborationTaskPastTargetDate: this.$icon('i.Users'),
        creditStatusChangedTo: this.$icon('i.Invoices'),
        riskCategoryChangedTo: this.$icon('i.RiskCategoryChanged')
      },
      mainPresetObs: null,
      mainPresetPart: new MainPresetPart(),
      priorities: [],
      comment: null,
      action: null,
      targetDate: null,
      user: null,
      priority: null,
      required: [
        v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')
      ],
      unwatch: () => { }
    }
  },
  activated () {
    this.mainPresetPart.active = true
    this.unwatch = this.$watch('showAll', this.showAllWatcher, { immediate: true })
  },
  mounted () {
    this.mainPresetPart.setKey('dashboard')
    this.mainPresetPart.active = true
    this.mainPresetObs = this.mainPresetPart.obs
    this.validateForm()
  },
  deactivated () {
    this.mainPresetPart.active = false
    this.unwatch()
  },
  destroyed () {
    this.mainPresetPart.free()
  },
  computed: {
    accountScope () {
      return Scope.account(this.$route.params.id)
    },
    showAll () {
      return typeof this.mainPresetObs?.doc?.myArea === 'undefined' ? undefined : !(this.mainPresetObs?.doc?.myArea)
    },
    itemsChecked () {
      return this.items.flatMap(i => i.items).filter(i => i.isChecked)
    },
    workItemsChecked () {
      return this.itemsChecked.filter(i => !i.isFollowUp)
    },
    followUpItemsChecked () {
      return this.itemsChecked.filter(i => i.isFollowUp)
    },
    form: {
      get () { return this.form1 },
      set (val) {
        this.$emit('document-can-save', { tab: this.tab, value: !!(val && this.action) })
        if (val && this.action) {
          this.$emit('document-changed', this.tab)
        }
      }
    }
  },
  methods: {
    createUpdateDates (i) {
      return {
        createAt: i.createAt,
        createBy: i.createBy,
        updateAt: i.updateAt,
        updateBy: i.updateBy
      }
    },
    async initToDo () {
      this.isLoading = true
      this.$emit('document-reset', this.tab)
      const accountId = await this.$waitFor(() => this.$route.params.id)
      this.comment = null
      const [
        reqWorkItems,
        reqPriorities,
        reqFollowUpSettings
      ] = await Promise.all([
        this.$http().get('/core/v6/accounts/' + accountId + '/work-items', { params: { showAll: this.showAll } }),
        this.$http().get('/core/v6/work-item-priorities'),
        this.$http().get('/core/v6/settings/follow-up')
      ])
      this.priorities = reqPriorities.data
      this.priority = this.priorities[1]
      this.allowFollowUpToBlockProtocol = reqFollowUpSettings.data.allowFollowUpToBlockProtocol
      this.isBlockingProtocol = reqFollowUpSettings.data.isBlockingProtocol
      this.isBlockingProtocolLocked = reqFollowUpSettings.data.isBlockingProtocolLocked

      this.items = reqWorkItems.data.items
      let hasWorkToDo = reqWorkItems.data.items.length

      if (!this.showAll) {
        hasWorkToDo = (await this.$http().get('/core/v6/accounts/' + accountId + '/work-items', { params: { showAll: true } })).data.items.length
      }

      this.$emit('hide-icon', { value: !hasWorkToDo })

      this.$nextTick(() => {
        this.$emit('document-ready', this.tab)
        this.isLoading = false
      })
    },
    async save () {
      const date = this.targetDate ? this.moment(this.targetDate).format('YYYY-MM-DDTHH:mm:ss') : null
      const httpQueries = []
      const payload = {
        updateAction: this.action,
        targetDate: date,
        userId: this.user?.id,
        comment: this.comment ?? undefined,
        priority: this.priority.id.toString(),
        isBlockingProtocol: this.isBlockingProtocol
      }
      if (this.workItemsChecked.length) {
        httpQueries.push(this.$http().post(
          '/core/v6/accounts/work-items',
          Object.assign({ ids: this.workItemsChecked.map(i => i.id) }, payload))
        )
      }
      if (this.followUpItemsChecked.length) {
        httpQueries.push(this.$http().post(
          '/core/v6/accounts/follow-ups',
          Object.assign({ ids: this.followUpItemsChecked.map(i => i.id) }, payload))
        )
      }
      return Promise.all(httpQueries)
    },
    async validateForm () {
      await this.$waitFor(() => this.$refs.form).then(form => { if (form) { form.validate() } })
    },
    showAllWatcher (v) {
      if (typeof v !== 'undefined') {
        this.initToDo()
      }
    },
    navigateTo (item) {
      const accountId = this.$route.params.id

      if (item.isFollowUp) {
        recentsController.addTab(tabController.create(tabController.workItemTemplatesTab(), { documentId: item.id, number: item.number }),
          {
            documentType: 'accounts',
            documentId: accountId
          })
      } else {
        switch (item.workItemType.code) {
          case 'disputePastTargetDate':
            recentsController.addTab(tabController.create(tabController.disputesTab(), { documentId: item.entityReference.id, number: item.entityReference.number }),
              {
                documentType: 'accounts',
                documentId: accountId
              })
            break
          case 'collaborationTaskPastTargetDate':
            recentsController.addTab(tabController.create(tabController.collaborationTasksTab(), { documentId: item.entityReference.id, number: item.entityReference.number }),
              {
                documentType: 'accounts',
                documentId: accountId
              })
            break
          case 'brokenPromiseToPay':
            recentsController.addTab(tabController.create(tabController.promisesTab(), { documentId: item.entityReference.id, number: item.entityReference.number }),
              {
                documentType: 'accounts',
                documentId: accountId
              })
            break
          case 'invoiceContestation':
          case 'invoiceValidation':
            recentsController.addTab(tabController.create(tabController.invoicesTab(), { documentId: item.entityReference.id, number: item.entityReference.number }),
              {
                documentType: 'accounts',
                documentId: accountId
              })
            break
        }
      }
    }
  },
  watch: {
    itemsChecked (val) {
      if (!val.length) {
        this.action = null
        this.targetDate = null
        this.user = null
        this.comment = null
        this.form = false
      } else {
        this.form = true
      }
      this.validateForm()
    },
    action () {
      if (this.action && this.itemsChecked.length === 1) {
        if (this.action !== 'reassign') {
          this.user = this.itemsChecked[0].userId ? { id: this.itemsChecked[0].userId } : null
        } else {
          this.user = null
        }
        this.comment = this.itemsChecked[0].comment
      }
      this.validateForm()
    },
    user () {
      this.validateForm()
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

.work-items
  display flex
  flex-direction column
  position relative
  height calc(100% - 4px)
  min-height 425px

.card-text-full-height
  height calc(100% - 150px)

.overflow-y
  overflow-y auto
  height 100%

.logo
  max-width 500px

.color-btn
  background-color var('bg-plain') !important
  opacity 0.3 !important

.white-color
  background-color white !important

.no-data
  margin auto

.description
  white-space pre-line
</style>
